import { Suspense } from 'react';
import { RouterProvider } from 'react-router-dom';

import router from '@app/router';
import { PageLoader } from '@components/ui/PageLoader';
import { Toaster } from '@ui/sonner';
import { SuperTokensWrapper } from 'supertokens-auth-react';

import ErrorBoundary from '../error-boundary';

const AppProvider = () => {
  return (
    <ErrorBoundary>
      <Suspense fallback={<PageLoader className="h-screen w-screen" />}>
        <SuperTokensWrapper>
          <RouterProvider
            router={router}
            fallbackElement={<PageLoader className="h-screen w-screen" />}
          />
        </SuperTokensWrapper>
      </Suspense>
      <Toaster />
    </ErrorBoundary>
  );
};

export default AppProvider;
