import { useEffect, useRef } from 'react';
import { Rive } from '@rive-app/react-canvas';

const RiveAnimation = () => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    if (canvasRef.current) {
      const riveInstance = new Rive({
        src: '/media/gcard-v3.riv',
        canvas: canvasRef.current,
        autoplay: true,
        stateMachines: ['run'],
        animations: ['walk'],
        onLoad: () => {
          riveInstance.resizeDrawingSurfaceToCanvas();
        },
      });

      return () => {
        riveInstance.cleanup();
      };
    }
  }, []);

  return (
    <canvas
      ref={canvasRef}
      className="h-full w-full md:h-[66.9svh] md:w-[62svh] lg:h-[686px] lg:w-[636px]"
    ></canvas>
  );
};
export default RiveAnimation;
