import { useEffect, useState } from 'react';

import Session from 'supertokens-auth-react/recipe/session';

export const useAuth = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [accessToken, setAccessToken] = useState<string | undefined>();
  const [userId, setUserId] = useState<string | undefined>();

  useEffect(() => {
    const checkSession = async () => {
      const [accessToken, sessionExists, userId] = await Promise.all([
        Session.getAccessToken(),
        Session.doesSessionExist(),
        Session.getUserId(),
      ]);
      setIsLoggedIn(sessionExists);
      setAccessToken(accessToken);
      setUserId(userId);
    };

    checkSession();
  }, []);

  return { isLoggedIn, accessToken, userId };
};
