import { lazy, Suspense } from 'react';

import { PageLoader } from '@components/ui/PageLoader';

const FAQPageLazy = lazy(() => import('./faq-page'));

const FAQPageAsync = () => {
  return (
    <Suspense fallback={<PageLoader />}>
      <FAQPageLazy />
    </Suspense>
  );
};

export { FAQPageAsync as FAQPage };
