/* eslint-disable max-len */
// TODO: Make component for link variants styling.
import * as React from 'react';

import { cn } from '@/lib/utils';
import { Slot } from '@radix-ui/react-slot';
import { cva, VariantProps } from 'class-variance-authority';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-xl text-base leading-4 font-medium transition-colors focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50 disabled:cursor-not-allowed leading-trim-both text-edge-cap font-feature-settings-off tracking-[0.48px] duration-300',
  {
    variants: {
      variant: {
        default: 'bg-neutral01 text-neutral07 hover:bg-neutral00',
        secondary: 'bg-neutral07 text-neutral01 hover:bg-neutral06',
        outline:
          'border-2 text-neutral01 bg-transparent hover:text-neutral00 hover:bg-neutral-04/20 hover:border-neutral04 border-neutral-shade-dark-0420',

        link: 'text-neutral01 text-opacity-50 underline-offset-4 focus-within:underline hover:underline focus:underline focus-visible:underline p-0',

        'form-link': `text-center text-neutral01 text-opacity-50
          underline-offset-4 duration-300 underline duration-200
          [&:not(:active)]:hover:opacity-70 transition-opacity
          `,

        destructive: 'bg-meaning-alert text-neutral01 hover:text-neutral00',
        'destructive-outline':
          'bg-transparent border-2 text-meaning-alert hover:border-meaning-alert hover:bg-meaning-alert/20 border-meaning-alert/20',
        success: 'bg-meaning-success text-neutral01 hover:text-neutral00',
        'success-outline':
          'bg-transparent hover:border-meaning-success border-meaning-success/20 border-2 text-meaning-success hover:bg-meaning-success/20',
        outlined:
          'bg-transparent border-2 hover:bg-neutral04/20 border-neutral04/20 active:bg-transparent',
        telegram: 'bg-telegram-button-gradient text-white',
      },
      size: {
        default: 'h-12 px-6 py-3',
        sm: 'h-9 px-4 tracking-[0.035rem] text-sm',
        lg: 'h-[60px]',
        xl: 'h-12 rounded-xl px-6 md:text-sm text-base',
        '2xl': 'h-[3.625rem] rounded-xl px-6 text-base',

        'icon-md': 'h-8 w-8',
        icon: 'h-10 w-10',

        'form-link-xs': `text-xs font-medium
          tracking-[0.48px] p-3 w-fit inline-block`,

        'rounded-2xl': 'rounded-[3.125rem] h-[3.375rem] py-4 px-6 gap-2',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants };
