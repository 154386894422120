import React from 'react';

import { IconProps } from './types';

export const Computer: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.5 17.25V17.988C22.5058 19.0073 21.7396 19.8658 20.7262 19.9754C20.5578 19.9919 20.4096 20 20.3 20H3.70002C3.54882 19.9977 3.39785 19.9873 3.24779 19.9687C2.24668 19.8451 1.49598 18.9927 1.50002 17.984V17.25C1.50002 17.1119 1.61195 17 1.75002 17H22.25C22.3881 17 22.5 17.1119 22.5 17.25Z"
      stroke="#BBBEC3"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 4H19.5C20.0523 4 20.5 4.44772 20.5 5V17H3.5V5C3.5 4.44772 3.94772 4 4.5 4Z"
      stroke="#BBBEC3"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
