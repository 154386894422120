import { lazy, Suspense } from 'react';

import { PageLoader } from '@components/ui/PageLoader';

const ConvertMoneyPageLazy = lazy(() => import('./convert-money-page'));

const ConvertMoneyPageAsync = () => {
  return (
    <Suspense fallback={<PageLoader className="pt-5" />}>
      <ConvertMoneyPageLazy />
    </Suspense>
  );
};

export { ConvertMoneyPageAsync as ConvertMoneyPage };
