import React from 'react';

import { IconProps } from './types';

export const Refresh: React.FC<IconProps> = ({ className }) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M13.3375 19.8641C17.27 18.8283 20.1667 15.2533 20.1667 11C20.1667 5.93998 16.0967 1.83331 11 1.83331C4.88584 1.83331 1.83334 6.92998 1.83334 6.92998M1.83334 6.92998V2.74998M1.83334 6.92998H3.67584H5.90334"
      stroke="#1A1D1F"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.83334 11C1.83334 16.06 5.94001 20.1667 11 20.1667"
      stroke="#1A1D1F"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="3 3"
    />
  </svg>
);
