import { lazy, Suspense } from 'react';

import { PageLoader } from '@components/ui/PageLoader';

const DashboardPageLazy = lazy(() => import('./dashboard-page'));

const DashboardPageAsync = () => {
  return (
    <Suspense fallback={<PageLoader className="pt-5 md:pt-0" />}>
      <DashboardPageLazy />
    </Suspense>
  );
};

export { DashboardPageAsync as DashboardPage };
