import './config';
import './styles/index.css';

import AppProvider from './providers/app-provider';
import QueryProvider from './providers/query-provider';
import '@shared/api/impersonation';

const App = () => {
  return (
    <QueryProvider>
      <AppProvider />
    </QueryProvider>
  );
};

export default App;
