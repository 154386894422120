import React from 'react';

import { IconProps } from './types';

export const TelegramIcon: React.FC<IconProps> = ({ className }) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <rect
      width="28"
      height="28"
      rx="14"
      fill="url(#paint0_linear_7816_15018)"
    />
    <rect width="28" height="28" rx="14" fill="#2CA5E0" />
    <path
      d="M20.9862 8.56811C21.1151 7.73518 20.3231 7.07774 19.5825 7.40291L4.83184 13.8793C4.30075 14.1124 4.33959 14.9169 4.89042 15.0923L7.93239 16.061C8.51296 16.2459 9.14163 16.1503 9.64861 15.8L16.5069 11.0618C16.7137 10.9189 16.9391 11.213 16.7624 11.3952L11.8257 16.485C11.3468 16.9787 11.4419 17.8153 12.0179 18.1766L17.5451 21.6426C18.165 22.0314 18.9626 21.6409 19.0785 20.8917L20.9862 8.56811Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_7816_15018"
        x1="18.1032"
        y1="4.42517"
        x2="11.1032"
        y2="20.7583"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#37AEE2" />
        <stop offset="1" stopColor="#1E96C8" />
      </linearGradient>
    </defs>
  </svg>
);
