import { notify } from '@/shared/hooks/use-toast';
import { routesMap, safeEnv } from '@/shared/lib';
import axios from 'axios';

import { UNKNOWN_ERROR } from './lib';
import { TAxiosDefaultError } from './types';

export { UNKNOWN_ERROR } from './lib';

export const client = axios.create({
  baseURL: safeEnv.VITE_CLIENT_API,
});

client.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && [429, 500, 502].includes(error.response.status)) {
      const currentPath = window.location.pathname + window.location.search;
      const errorUrl = `${routesMap.error}?returnUrl=${encodeURIComponent(currentPath)}`;
      window.location.href = errorUrl;
    }
    return Promise.reject(error);
  },
);

export const getAxiosError = (
  error: unknown,
  messageKey: 'error' | 'message' = 'error',
) => {
  const typedError = error as TAxiosDefaultError;

  return typedError?.response?.data?.[messageKey];
};

interface IToastAxiosError {
  error?: unknown;
  messages?: { [key in string]: string };
  messageKey?: 'error' | 'message';
}

export const toastAxiosError = ({
  error,
  messages = {},
  messageKey = 'error',
}: IToastAxiosError = {}) => {
  const axiosError = getAxiosError(error, messageKey);

  const message = (
    messageKey === 'message'
      ? axiosError
      : (axiosError as unknown as TAxiosDefaultError)?.message
  ) as keyof typeof messages;

  const toast = notify;

  if (!message || !axiosError || !messages[message]) {
    return toast('error', {
      title: UNKNOWN_ERROR,
    });
  }

  toast('error', {
    title: messages[message],
  });
};
