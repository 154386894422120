import { lazy, Suspense } from 'react';

import { PageLoader } from '@components/ui/PageLoader';

const RequestCardPageLazy = lazy(() => import('./request-card-page'));

const RequestCardPageAsync = () => {
  return (
    <Suspense fallback={<PageLoader />}>
      <RequestCardPageLazy />
    </Suspense>
  );
};

export { RequestCardPageAsync as RequestCardPage };
