import { lazy, Suspense } from 'react';

import { PageLoader } from '@components/ui/PageLoader';

const CreateSavingAccountPageLazy = lazy(
  () => import('./create-saving-account/create-saving-account-page'),
);
const CheckingAccountsPageLazy = lazy(() => import('./checking-accounts-page'));
const AccountDetailsPageLazy = lazy(
  () => import('./details/account-details-page'),
);

const CreateSavingAccountPageAsync = () => {
  return (
    <Suspense fallback={<PageLoader />}>
      <CreateSavingAccountPageLazy />
    </Suspense>
  );
};

const CheckingAccountsPageAsync = () => {
  return (
    <Suspense fallback={<PageLoader />}>
      <CheckingAccountsPageLazy />
    </Suspense>
  );
};

const AccountDetailsPageAsync = () => {
  return (
    <Suspense fallback={<PageLoader />}>
      <AccountDetailsPageLazy />
    </Suspense>
  );
};

export {
  CreateSavingAccountPageAsync as CreateSavingAccountPage,
  CheckingAccountsPageAsync as CheckingAccountsPage,
  AccountDetailsPageAsync as AccountDetailsPage,
};
