import { create } from 'zustand';
import { client } from '.';
import { useLayoutEffect, useRef, useState } from 'react';
import { persist } from 'zustand/middleware';

interface ImpersonationState {
  impersonationToken: string | null;
  setImpersonationToken: (token: string | null) => void;
}

const getTokenFromParams = () => {
  const params = new URLSearchParams(window.location.search);
  const token = params.get('impersonationClient');

  return { token, params };
};

export const useImpersonationStore = create(
  persist<ImpersonationState>(
    (set) => ({
      impersonationToken: getTokenFromParams().token,
      setImpersonationToken: (token) => set({ impersonationToken: token }),
    }),
    {
      name: 'impersonation-token',
      getStorage: () => sessionStorage,
    },
  ),
);

const handleImpersonationToken = () => {
  const { token: paramsToken, params } = getTokenFromParams();

  const token =
    paramsToken || useImpersonationStore.getState().impersonationToken;

  if (token) {
    useImpersonationStore.getState().setImpersonationToken(token);

    params.delete('impersonationToken');
    const newSearch = params.toString();
    const newUrl = `${window.location.pathname}${newSearch ? `?${newSearch}` : ''}`;
    window.history.replaceState({}, '', newUrl);
  }
};

client.interceptors.request.use(
  async (config) => {
    const { impersonationToken } = useImpersonationStore.getState();
    if (impersonationToken) {
      config.headers['Authorization'] = `Bearer ${impersonationToken}`;
    }

    return config;
  },
  (error) => Promise.reject(error),
);

client.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error?.response?.status === 401) {
      const { impersonationToken, setImpersonationToken } =
        useImpersonationStore.getState();

      if (impersonationToken) {
        setImpersonationToken(null);
      }
    }

    return Promise.reject(error);
  },
);

export const useImpersonationLayout = () => {
  const { impersonationToken } = useImpersonationStore();
  const [isMounted, setIsMounted] = useState(false);

  const wasTokenSet = useRef(!!impersonationToken);

  useLayoutEffect(() => {
    if (!isMounted) {
      handleImpersonationToken();
      setIsMounted(true);
    }

    if (impersonationToken) {
      wasTokenSet.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [impersonationToken]);

  if (
    (!impersonationToken && wasTokenSet.current) ||
    (!wasTokenSet.current && impersonationToken)
  ) {
    location.reload();
  }

  return { impersonationToken };
};
