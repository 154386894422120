import { GenericStateCreator } from '../types/index';

interface SupportState {
  isSupportModalOpen: boolean;
}

interface SupportActions {
  setIsSupportModalOpen: (value: boolean) => void;
}

export type SupportSlice = SupportState & SupportActions;

const initialState: SupportState = {
  isSupportModalOpen: false,
};

export const createSupportSlice: GenericStateCreator<SupportSlice> = (set) => ({
  ...initialState,
  setIsSupportModalOpen: (value: boolean) => {
    set((state) => {
      state.isSupportModalOpen = value;
    });
  },
});
