import { lazy, Suspense } from 'react';

import { PageLoader } from '@components/ui/PageLoader';

const SavingsAccountsPageLazy = lazy(async () => {
  await new Promise((resolve) => setTimeout(resolve, 1000));
  return import('./savings-page');
});

const SavingDetailsPageLazy = lazy(
  () => import('./details/saving-details-page'),
);

const SavingsAccountsPageAsync = () => {
  return (
    <Suspense fallback={<PageLoader />}>
      <SavingsAccountsPageLazy />
    </Suspense>
  );
};

const SavingDetailsPageAsync = () => {
  return (
    <Suspense fallback={<PageLoader />}>
      <SavingDetailsPageLazy />
    </Suspense>
  );
};

export {
  SavingsAccountsPageAsync as SavingsAccountsPage,
  SavingDetailsPageAsync as SavingDetailsPage,
};
