import { lazy, Suspense } from 'react';

import { PageLoader } from '@components/ui/PageLoader';

const NotificationsPageLazy = lazy(() => import('./ui/notifications-page'));

const NotificationsPageAsync = () => {
  return (
    <Suspense fallback={<PageLoader />}>
      <NotificationsPageLazy />
    </Suspense>
  );
};

export { NotificationsPageAsync as NotificationsPage };
