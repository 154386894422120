import { useEffect } from 'react';

import { client } from '@shared/api';
import { useAuth } from '@shared/hooks';
import { displaySuccessForOfflineAction } from '@shared/lib';
import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

export type TCountry = string;

export interface IPaginatedCountries {
  results: TCountry[];
}

export interface IOffice {
  id: string;
  country: string;
  city: string;
  street: string | null;
  email: string;
  phone: string;
  dateLag: number;
  timeZone: string;
}

export interface IPaginatedOffices {
  results: IOffice[];
  total: number;
}

export interface IWhereAmI {
  country: string;
  region: string;
  city: string;
  timezone: string;
}

export interface IGetCountriesParams {
  query?: string;
}

export interface IGetOfficesParams {
  take?: number;
  skip?: number;
  query?: string;
}

export const useGetCountries = (
  params: IGetCountriesParams = {},
  options?: Omit<
    UseQueryOptions<IPaginatedCountries, AxiosError>,
    'queryKey' | 'queryFn'
  >,
) => {
  const query = useQuery<IPaginatedCountries, AxiosError>({
    queryKey: ['countries', params],
    queryFn: () => client.get('/countries', { params }).then((res) => res.data),
    ...options,
  });

  return {
    countries: query.data?.results || [],
    ...query,
  };
};

export interface ITelegramResponse {
  url: string;
}

export const useGetOffices = (
  params: IGetOfficesParams = {},
  options?: Omit<
    UseQueryOptions<IPaginatedOffices, AxiosError>,
    'queryKey' | 'queryFn'
  >,
) => {
  const query = useQuery<IPaginatedOffices, AxiosError>({
    queryKey: ['offices', params],
    queryFn: () => client.get('/offices', { params }).then((res) => res.data),
    ...options,
  });

  return {
    offices: query.data?.results || [],
    total: query.data?.total || 0,
    ...query,
  };
};

export const useWhereAmI = (
  options?: Omit<
    UseQueryOptions<IWhereAmI, AxiosError>,
    'queryKey' | 'queryFn'
  >,
) => {
  const query = useQuery<IWhereAmI, AxiosError>({
    queryKey: ['whereami'],
    queryFn: () => client.get('/whereami').then((res) => res.data),
    ...options,
  });

  return {
    location: query.data,
    ...query,
  };
};

export const useGetTelegramClient = () => {
  const mutation = useMutation<ITelegramResponse, AxiosError>({
    mutationFn: () =>
      client.post('/client/auth/telegram').then((res) => res.data),
  });

  return {
    getTelegramClient: mutation.mutateAsync,
    ...mutation,
  };
};

export const useTelegramLink = () => {
  const {
    getTelegramClient,
    isPending,
    isSuccess,
    data: telegramData,
  } = useGetTelegramClient();

  const { accessToken } = useAuth();

  const telegramUrl = telegramData?.url || 'https://t.me/gcard_robot';

  const openTelegramBot = () => window.open(telegramUrl, '_blank');

  useEffect(() => {
    (async () => {
      try {
        if (accessToken) getTelegramClient();
      } catch {
        if (!navigator.onLine) {
          displaySuccessForOfflineAction();
        }
      }
    })();
  }, [getTelegramClient, accessToken]);

  return {
    isPending,
    telegramUrl,
    openTelegramBot,
    isSuccess,
  };
};
