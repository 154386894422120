import React from 'react';

interface ImpersonationOverlayProps {
  isActive: boolean;
}

export const ImpersonationOverlay: React.FC<ImpersonationOverlayProps> = ({
  isActive,
}) => {
  if (!isActive) return null;

  return (
    <div className="relative z-[99999999]">
      <div className="pointer-events-none fixed inset-0 h-full w-full border-4 border-red-500"></div>
      <div className="fixed left-1/2 top-0 -translate-x-1/2 rounded-t-sm bg-red-500 p-1 text-center text-xs">
        Impersonation mode
      </div>
    </div>
  );
};
