import { Component, ErrorInfo, ReactNode } from 'react';

import BlockingError from '@components/error/blocking-error';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <BlockingError
          actionText="Oops! Something went wrong"
          description="We're sorry, but it seems like there was an unexpected error. Please try refreshing the page."
          onRefresh={() => window.location.reload()}
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
