import { EToastId, EToastStatus, updateToastStatus } from '@shared/types';

import { GenericStateCreator } from '../types';

interface ToastState {
  toasts: {
    [key in EToastId]?: EToastStatus;
  };
  userToasts: {
    [key in EToastId]?: EToastStatus;
  };
}

interface ToastActions {
  updateToastStatus: updateToastStatus;
  updateUserToastStatus: updateToastStatus;
}

export type ToastSlice = ToastState & ToastActions;

const initialState: ToastState = {
  toasts: {},
  userToasts: {},
};

export const createToastSlice: GenericStateCreator<ToastSlice> = (set) => ({
  ...initialState,
  updateToastStatus: (id: EToastId, status: EToastStatus) => {
    set((state) => ({
      toasts: { ...state.toasts, [id]: status },
    }));
  },
  updateUserToastStatus: (id: EToastId, status: EToastStatus) => {
    set((state) => ({
      userToasts: { ...state.userToasts, [id]: status },
    }));
  },
});
