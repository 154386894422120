import { lazy, Suspense } from 'react';

import { PageLoader } from '@components/ui/PageLoader';

const CardDetailsPageLazy = lazy(() => import('./details/card-details-page'));

const CardDetailsPageAsync = () => {
  return (
    <Suspense fallback={<PageLoader />}>
      <CardDetailsPageLazy />
    </Suspense>
  );
};

export { CardDetailsPageAsync as CardDetailsPage };
