import { lazy, Suspense } from 'react';

import { PageLoader } from '@components/ui/PageLoader';

const TopupPageLazy = lazy(() => import('./topup-page'));

const TopupPageAsync = () => {
  return (
    <Suspense fallback={<PageLoader className="pt-5" />}>
      <TopupPageLazy />
    </Suspense>
  );
};

export { TopupPageAsync as TopupPage };
