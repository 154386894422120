import { FC } from 'react';

import { Loader } from '@ui/loader';
import clsx from 'clsx';

interface PageLoaderProps {
  className?: string;
}

export const PageLoader: FC<PageLoaderProps> = ({ className }) => {
  return (
    <div
      className={clsx(
        'flex h-full w-full items-center justify-center',
        className,
      )}
    >
      <Loader />
    </div>
  );
};

export default PageLoader;
