import { lazy, Suspense } from 'react';

import { PageLoader } from '@components/ui/PageLoader';

const WithdrawalPageLazy = lazy(() => import('./withdrawal-page'));

const WithdrawalPageAsync = () => {
  return (
    <Suspense fallback={<PageLoader className="pt-5" />}>
      <WithdrawalPageLazy />
    </Suspense>
  );
};

export { WithdrawalPageAsync as WithdrawalPage };
