import { lazy, Suspense } from 'react';

import { PageLoader } from '@components/ui/PageLoader';

const ErrorPageWrapperLazy = lazy(() => import('./error-page-wrapper'));

const ErrorPageWrapperAsync = () => {
  return (
    <Suspense fallback={<PageLoader className="h-screen w-screen" />}>
      <ErrorPageWrapperLazy />
    </Suspense>
  );
};

export { ErrorPageWrapperAsync as ErrorPageWrapper };
