import { lazy, Suspense } from 'react';

import { PageLoader } from '@components/ui/PageLoader';

const TransactionsPageLazy = lazy(() => import('./transactions-page'));

const TransactionsPageAsync = () => {
  return (
    <Suspense fallback={<PageLoader className="pt-5" />}>
      <TransactionsPageLazy />
    </Suspense>
  );
};

export { TransactionsPageAsync as TransactionsPage };
