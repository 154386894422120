import { GenericStateCreator } from '../types';

interface NotificationState {
  pushPermission: NotificationPermission;
  emailPermission: boolean;
}

interface NotificationActions {
  setNotificationPermission: (pushPermission: NotificationPermission) => void;
  setEmailPermission: (emailPermission: boolean) => void;
}

export type NotificationSlice = NotificationState & NotificationActions;

const initialState: NotificationState = {
  pushPermission: 'default',
  emailPermission: false,
};

export const createNotificationSlice: GenericStateCreator<NotificationSlice> = (
  set,
) => ({
  ...initialState,
  setNotificationPermission: (pushPermission) => {
    set({ pushPermission });
  },
  setEmailPermission: (emailPermission) => {
    set({ emailPermission });
  },
});
