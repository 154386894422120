import { Loader2 } from 'lucide-react';

interface SpinnerProps {
  size?: number;
  className?: string;
}

export const Loader = ({ size = 48, className = '' }: SpinnerProps) => {
  return (
    <Loader2
      className={`text-primary w-full animate-spin ${className}`}
      size={size}
    />
  );
};
