import { ECurrency } from '@lib/currency';

export const currencyCodes = {
  [ECurrency.USD]: 'us',
  [ECurrency.EUR]: 'eu',
  [ECurrency.GBP]: 'gb',
  [ECurrency.CNY]: 'cn',
  [ECurrency.RUB]: 'ru',
  [ECurrency.CHF]: 'ch',
  [ECurrency.AED]: 'ae',
  [ECurrency.PLN]: 'pl',
  [ECurrency.RSD]: 'rs',
  [ECurrency.THB]: 'th',
  [ECurrency.AZN]: 'az',
  [ECurrency.RON]: 'ro',
  [ECurrency.TRY]: 'tr',
  [ECurrency.BHD]: 'bh',
  [ECurrency.QAR]: 'qa',
  [ECurrency.MAD]: 'ma',
  [ECurrency.OMR]: 'om',
  [ECurrency.UZS]: 'uz',
  [ECurrency.BGN]: 'bg',
  [ECurrency.AFN]: 'af',
  [ECurrency.ALL]: 'al',
  [ECurrency.AMD]: 'am',
  [ECurrency.AOA]: 'ao',
  [ECurrency.ARS]: 'ar',
  [ECurrency.AUD]: 'au',
  [ECurrency.AWG]: 'aw',
  [ECurrency.BAM]: 'ba',
  [ECurrency.BBD]: 'bb',
  [ECurrency.BDT]: 'bd',
  [ECurrency.BIF]: 'bi',
  [ECurrency.BMD]: 'bm',
  [ECurrency.BND]: 'bn',
  [ECurrency.BOB]: 'bo',
  [ECurrency.BOV]: 'bo',
  [ECurrency.BRL]: 'br',
  [ECurrency.BSD]: 'bs',
  [ECurrency.BTN]: 'bt',
  [ECurrency.BWP]: 'bw',
  [ECurrency.BYN]: 'by',
  [ECurrency.BZD]: 'bz',
  [ECurrency.CAD]: 'ca',
  [ECurrency.CDF]: 'cd',
  [ECurrency.CLF]: 'cl',
  [ECurrency.CLP]: 'cl',
  [ECurrency.COP]: 'co',
  [ECurrency.CRC]: 'cr',
  [ECurrency.CUC]: 'cu',
  [ECurrency.CUP]: 'cu',
  [ECurrency.CVE]: 'cv',
  [ECurrency.CZK]: 'cz',
  [ECurrency.DJF]: 'dj',
  [ECurrency.DKK]: 'dk',
  [ECurrency.DOP]: 'do',
  [ECurrency.DZD]: 'dz',
  [ECurrency.EGP]: 'eg',
  [ECurrency.ERN]: 'er',
  [ECurrency.ETB]: 'et',
  [ECurrency.FJD]: 'fj',
  [ECurrency.FKP]: 'fk',
  [ECurrency.GEL]: 'ge',
  [ECurrency.GHS]: 'gh',
  [ECurrency.GIP]: 'gi',
  [ECurrency.GMD]: 'gm',
  [ECurrency.GNF]: 'gn',
  [ECurrency.GTQ]: 'gt',
  [ECurrency.GYD]: 'gy',
  [ECurrency.HKD]: 'hk',
  [ECurrency.HNL]: 'hn',
  [ECurrency.HRK]: 'hr',
  [ECurrency.HTG]: 'ht',
  [ECurrency.HUF]: 'hu',
  [ECurrency.IDR]: 'id',
  [ECurrency.ILS]: 'il',
  [ECurrency.INR]: 'in',
  [ECurrency.IQD]: 'iq',
  [ECurrency.IRR]: 'ir',
  [ECurrency.ISK]: 'is',
  [ECurrency.JMD]: 'jm',
  [ECurrency.JOD]: 'jo',
  [ECurrency.JPY]: 'jp',
  [ECurrency.KES]: 'ke',
  [ECurrency.KGS]: 'kg',
  [ECurrency.KHR]: 'kh',
  [ECurrency.KMF]: 'km',
  [ECurrency.KPW]: 'kp',
  [ECurrency.KRW]: 'kr',
  [ECurrency.KWD]: 'kw',
  [ECurrency.KYD]: 'ky',
  [ECurrency.KZT]: 'kz',
  [ECurrency.LAK]: 'la',
  [ECurrency.LBP]: 'lb',
  [ECurrency.LKR]: 'lk',
  [ECurrency.LRD]: 'lr',
  [ECurrency.LSL]: 'ls',
  [ECurrency.LTL]: 'lt',
  [ECurrency.LVL]: 'lv',
  [ECurrency.LYD]: 'ly',
  [ECurrency.MDL]: 'md',
  [ECurrency.MGA]: 'mg',
  [ECurrency.MKD]: 'mk',
  [ECurrency.MMK]: 'mm',
  [ECurrency.MNT]: 'mn',
  [ECurrency.MOP]: 'mo',
  [ECurrency.MRO]: 'mr',
  [ECurrency.MUR]: 'mu',
  [ECurrency.MVR]: 'mv',
  [ECurrency.MWK]: 'mw',
  [ECurrency.MXN]: 'mx',
  [ECurrency.MYR]: 'my',
  [ECurrency.MZN]: 'mz',
  [ECurrency.NAD]: 'na',
  [ECurrency.NGN]: 'ng',
  [ECurrency.NIO]: 'ni',
  [ECurrency.NOK]: 'no',
  [ECurrency.NPR]: 'np',
  [ECurrency.NZD]: 'nz',
  [ECurrency.PAB]: 'pa',
  [ECurrency.PEN]: 'pe',
  [ECurrency.PGK]: 'pg',
  [ECurrency.PHP]: 'ph',
  [ECurrency.PKR]: 'pk',
  [ECurrency.PYG]: 'py',
  [ECurrency.RWF]: 'pw',
  [ECurrency.SAR]: 'sa',
  [ECurrency.SBD]: 'sb',
  [ECurrency.SCR]: 'sc',
  [ECurrency.SDG]: 'sd',
  [ECurrency.SEK]: 'se',
  [ECurrency.SGD]: 'sg',
  [ECurrency.SHP]: 'sh',
  [ECurrency.SLL]: 'sl',
  [ECurrency.SOS]: 'so',
  [ECurrency.SRD]: 'sr',
  [ECurrency.SSP]: 'ss',
  [ECurrency.STD]: 'st',
  [ECurrency.SVC]: 'sv',
  [ECurrency.SYP]: 'sy',
  [ECurrency.SZL]: 'sz',
  [ECurrency.TJS]: 'tj',
  [ECurrency.TMT]: 'tm',
  [ECurrency.TND]: 'tn',
  [ECurrency.TOP]: 'to',
  [ECurrency.TTD]: 'tt',
  [ECurrency.TWD]: 'tw',
  [ECurrency.TZS]: 'tz',
  [ECurrency.UAH]: 'ua',
  [ECurrency.UGX]: 'ug',
  [ECurrency.UYU]: 'uy',
  [ECurrency.VEF]: 've',
  [ECurrency.VND]: 'vn',
  [ECurrency.VUV]: 'vu',
  [ECurrency.WST]: 'ws',
  [ECurrency.YER]: 'ye',
  [ECurrency.ZAR]: 'za',
  [ECurrency.ZMW]: 'zm',
  [ECurrency.ZWL]: 'zw',
};

export const CURRENCY_NAMES: Partial<Record<ECurrency, string>> = {
  [ECurrency.USD]: 'US Dollar',
  [ECurrency.EUR]: 'Euro',
  [ECurrency.GBP]: 'British Pound',
  [ECurrency.CNY]: 'Chinese Yuan',
  [ECurrency.RUB]: 'Russian Ruble',
  [ECurrency.CHF]: 'Swiss Franc',
  [ECurrency.AED]: 'UAE Dirham',
  [ECurrency.PLN]: 'Polish Zloty',
  [ECurrency.RSD]: 'Serbian Dinar',
  [ECurrency.THB]: 'Thai Baht',
  [ECurrency.AZN]: 'Azerbaijani Manat',
  [ECurrency.RON]: 'Romanian Leu',
  [ECurrency.TRY]: 'Turkish Lira',
  [ECurrency.BHD]: 'Bahraini Dinar',
  [ECurrency.QAR]: 'Qatari Riyal',
  [ECurrency.MAD]: 'Moroccan Dirham',
  [ECurrency.OMR]: 'Omani Rial',
  [ECurrency.UZS]: 'Uzbekistani Som',
  [ECurrency.BGN]: 'Bulgarian Lev',
};

export const getCurrencies = () => {
  return Object.values(ECurrency);
};
