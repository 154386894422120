import MainNotification, { NotificationProps } from '@ui/mainNotifications';
import { ExternalToast, toast } from 'sonner';
import { v4 } from 'uuid';

type ToastType = 'success' | 'error' | 'info' | 'warning';

interface NotifyOptions extends Omit<NotificationProps, 'type'> {
  duration?: number | typeof Infinity;
}

export const notify = (
  type: ToastType,
  options: NotifyOptions,
  params?: ExternalToast,
) => {
  const cryptoId = v4();

  const typedToast = toast[type];

  if (!typedToast) return;

  const { duration, id, ...restOptions } = options;

  typedToast(
    <MainNotification type={type} id={id ?? cryptoId} {...restOptions} />,
    {
      ...params,
      id: params?.id ?? cryptoId,
      duration,
    },
  );
};

export const useToast = () => {
  return notify;
};
