import { ImpersonationOverlay } from '@components/impersonation-overlay';
import { RootLayout } from '@components/layout';
import { useImpersonationLayout } from '@shared/api/impersonation';
import { SessionAuth } from 'supertokens-auth-react/recipe/session';

export const ProtectedLayout = () => {
  const { impersonationToken } = useImpersonationLayout();

  return (
    <SessionAuth requireAuth={!impersonationToken}>
      <RootLayout />
      <ImpersonationOverlay isActive={!!impersonationToken} />
    </SessionAuth>
  );
};
