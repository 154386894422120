import { useCallback } from 'react';

import * as PusherPushNotifications from '@pusher/push-notifications-web';
import { safeEnv } from '@shared/lib';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';

import { useToast } from './use-toast';

export let beamsClient: PusherPushNotifications.Client | null = null;
let tokenProvider: PusherPushNotifications.TokenProvider | null = null;

window.navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
  tokenProvider = new PusherPushNotifications.TokenProvider({
    url: `${safeEnv.VITE_CLIENT_API}/auth/pusher/token`,
  });

  beamsClient = new PusherPushNotifications.Client({
    instanceId: safeEnv.VITE_PUSHER_BEAMS_INSTANCE_ID,
    serviceWorkerRegistration,
  });
});

export const usePushSubscribe = () => {
  const toast = useToast();
  const context = useSessionContext();

  const getSubscription = useCallback(async () => {
    try {
      await beamsClient?.start();

      if (!context.loading && tokenProvider) {
        await beamsClient?.setUserId(context.userId, tokenProvider);
      }
    } catch (e) {
      toast('error', { title: 'Failed to subscribe to push notifications' });
      console.log(e);
    }
  }, [context, toast]);

  return { getSubscription };
};
