import { GenericStateCreator } from '../types';

interface CurrencyState {
  currency: string;
}

interface CurrencyActions {
  setCurrency: (currency: string) => void;
}

export type CurrencySlice = CurrencyState & CurrencyActions;

const initialState: CurrencyState = {
  currency: localStorage.getItem('currency') || 'USD',
};

export const createCurrencySlice: GenericStateCreator<CurrencySlice> = (
  set,
) => ({
  ...initialState,
  setCurrency: (newCurrency: string) => {
    localStorage.setItem('currency', newCurrency);
    set((state) => {
      state.currency = newCurrency;
    });
  },
});
