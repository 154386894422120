import { GenericStateCreator } from '../types/index';

interface NavbarState {
  isCardsOpen: boolean;
}

interface NavbarDesktopState {
  isCardsDesktopOpen: boolean;
}

interface NavbarCardSliderState {
  isCardSliderOpen: boolean;
}

interface NavbarActions {
  setCardsNavbarOpen: (value: boolean) => void;
}

interface NavbarDesktopActions {
  setCardsDesktopOpen: (value: boolean) => void;
}

interface NavbarCardSliderActions {
  setCardSliderOpen: (value: boolean) => void;
}

export type NavbarSlice = NavbarState & NavbarActions;
export type NavbarDesktopSlice = NavbarDesktopState & NavbarDesktopActions;
export type NavbarCardSliderSlice = NavbarCardSliderState &
  NavbarCardSliderActions;

const initialState: NavbarState = {
  isCardsOpen: false,
};

const initialDesktopState: NavbarDesktopState = {
  isCardsDesktopOpen: false,
};

const initialCardSliderState: NavbarCardSliderState = {
  isCardSliderOpen: false,
};

export const createNavbarSlice: GenericStateCreator<NavbarSlice> = (set) => ({
  ...initialState,
  setCardsNavbarOpen: (value: boolean) => {
    set((state) => {
      state.isCardsOpen = value;
    });
  },
});

export const createNavbarDesktopSlice: GenericStateCreator<
  NavbarDesktopSlice
> = (set) => ({
  ...initialDesktopState,
  setCardsDesktopOpen: (value: boolean) => {
    set((state) => {
      state.isCardsDesktopOpen = value;
    });
  },
});

export const createCardSliderSlice: GenericStateCreator<
  NavbarCardSliderSlice
> = (set) => ({
  ...initialCardSliderState,
  setCardSliderOpen: (value: boolean) => {
    set((state) => {
      state.isCardSliderOpen = value;
    });
  },
});
