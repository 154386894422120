import { lazy, Suspense } from 'react';

import { PageLoader } from '@components/ui/PageLoader';

const RootLayoutLazy = lazy(() => import('./root-layout'));

const RootLayoutAsync = () => {
  return (
    <Suspense fallback={<PageLoader className="h-screen w-screen" />}>
      <RootLayoutLazy />
    </Suspense>
  );
};

export { RootLayoutAsync as RootLayout };
