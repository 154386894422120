import React from 'react';

import { IconProps } from './types';

export const MobilePhone: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 21H8C6.895 21 6 20.105 6 19V5C6 3.895 6.895 3 8 3H16C17.105 3 18 3.895 18 5V19C18 20.105 17.105 21 16 21Z"
      stroke="#BBBEC3"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.999 17.25C11.861 17.25 11.749 17.362 11.75 17.5C11.75 17.638 11.862 17.75 12 17.75C12.138 17.75 12.25 17.638 12.25 17.5C12.25 17.362 12.138 17.25 11.999 17.25"
      stroke="#BBBEC3"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.75 6H13.25"
      stroke="#BBBEC3"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
